import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from "next/image";
// import { HashLink } from "react-router-hash-link";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 bg-jacarta-900 page-footer">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-24 md:grid-cols-12">
            <div className="col-span-12 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#">
                <a className="mb-6 inline-block">
                  <img
                    src="/images/LogoCirsys.svg"
                    className="max-h-7 dark:hidden hidden"
                    alt="CIRSYS Tech"
                  />
                </a>
              </Link>

              <Link href="#">
                <a className=" mb-6 inline-block">
                  <img
                    src="/images/LogoCirsys.svg"
                    className="max-h-7 dark:block block mb-6"
                    alt="CIRSYS Tech"
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-300 text-jacarta-300 mb-12 font-thin">Desarrollamos tecnologia para el beneficio social.<br />© CIRSYS TECH {new Date().getFullYear()} - All Rights Reserved.</p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent ease-out duration-200">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h4 className="text-white mb-6 text-sm dark:text-white tracking-widest">
                  {single.title}
                </h4>
                <ul className="dark:text-jacarta-300 text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link href={href} >
                          <a className="hover:text-accent dark:hover:text-accent ease-out duration-200">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
        

        {/* <!-- HOKUP footer --> */}
        <a href="https://hokup.com.br" target="_blank" rel="noopener noreferrer" className="dark:bg-accent bg-accent flex flex-row items-center h-10 w-screen group hover:dark:bg-white ease-out duration-200">
          <div className="container flex items-center ">
            <svg className="fill-bg-black h-4 w-4 dark:group-hover:fill-jacarta-1400 group-hover:fill-jacarta-1400 ease-out duration-200">
              <use xlinkHref={`/icons.svg#icon-hokup`}></use>
            </svg>
            <p className="pl-2 dark:text-black text-black text-sm font-semibold">hokup</p>
          </div>
        </a>

      </footer>
    </>
  );
};

export default footer;
