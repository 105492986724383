import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/LogoCirsys.svg";
import WhiteLogo from "./../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import WalletButton from "../wallet-btn/WalletButton";

export default function Header01() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header className="js-page-header fixed top-0 z-20 w-full transition-colors header-custom header-styles"
      // style={{
      //   background:'#0e15168f',
      //   backdropFilter:'blur(12px)',
      // }}
      >
        <div className="flex items-center lg:px-6 px-4 lg:py-6 py-4 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <a>
              <div className="dark:hidden hidden">
                <Image
                  src={Logo}
                  height={30}
                  width={150}
                  alt="CIRSYS Tech"
                  className="max-h-7 h-auto"
                />
              </div>
              <div className="dark:block block"
              style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
              }}
              >
                <Image
                  src={Logo}
                  height={30}
                  width={150}
                  alt="CIRSYS Tech"
                />
              </div>
            </a>
          </Link>
          {/* End  logo */}

       
          {/* End Desktop search form */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-jacarta-800 opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* HOME */}
                <li className="group">
                  <Link href="/">
                    <a className="dropdown-toggle tracking-widest hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white text-white lg:px-5 w-full ease-out duration-200">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/")
                              ? "text-accent dark:text-accent text-xs"
                              : "text-xs"
                          }
                        >
                          HOME
                        </span>
                    </a>
                  </Link>
                </li>

                {/* IRBIN */}
                <li className="group">
                  <Link href="/irbin">
                    <a className="dropdown-toggle tracking-widest hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white text-white lg:px-5 w-full ease-out duration-200">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/irbin")
                              ? "text-accent dark:text-accent text-xs"
                              : "text-xs"
                          }
                        >
                          IRBIN
                        </span>
                    </a>
                  </Link>
                </li>

                {/* PROYECTOS */}
                <li className="group">
                  <Link href="/proyectos">
                    <a className="dropdown-toggle tracking-widest hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white text-white lg:px-5 w-full ease-out duration-200">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/proyectos")
                              ? "text-accent dark:text-accent text-xs"
                              : "text-xs"
                          }
                        >
                          PROYECTOS
                        </span>
                    </a>
                  </Link>
                </li>

                {/* ACERCA */}
                <li className="group">
                  <Link href="/acerca">
                    <a className="dropdown-toggle tracking-widest hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white text-white lg:px-5 w-full ease-out duration-200">
                    <span
                          className={
                            isChildrenPageActive(route.asPath, "/acerca")
                              ? "text-accent dark:text-accent text-xs"
                              : "text-xs"
                          }
                        >
                          ACERCA
                        </span>
                    </a>
                  </Link>
                </li>

                {/* CONTACTO */}
                <li className="group">
                  <Link href="/contacto">
                    <a className="dropdown-toggle tracking-widest hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white text-white lg:px-5 w-full ease-out duration-200">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/contacto")
                              ? "text-accent dark:text-accent text-xs"
                              : "text-xs"
                          }
                        >
                          CONTACTO
                        </span>
                    </a>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* End menu for desktop */}


          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">
            
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-white h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* MOBILE MENU  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-1300 bg-jacarta-1300 invisible fixed inset-0 z-20 ml-auto items-center opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-1300 bg-jacarta-1300 fixed left-0 z-10 flex w-full items-center justify-between p-6 lg:hidden">
        

          <Link className="block dark:block" href="/">
            <a onClick={() => setToggle(false)}>
            <Image
                  src={Logo}
                  height={30}
                  width={150}
                  alt="CIRSYS Tech"
                />
            </a>
          </Link>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border dark:bg-white/[.15] transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-white h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>

       
        {/* NAV */}
        <nav className="navbar w-full mt-24 ">
          <ul className="flex flex-col lg:flex-row">
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/">
                <a className="text-white tracking-widest uppercase hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-xs dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      HOME
                    </span>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/irbin">
                <a className="text-white tracking-widest uppercase hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-xs dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/irbin", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      IRBIN
                    </span>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/proyectos">
                <a className="text-white tracking-widest uppercase hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-xs dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/proyectos", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      PROYECTOS
                    </span>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/acerca">
                <a className="text-white tracking-widest uppercase hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-xs dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/acerca", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      ACERCA
                    </span>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/contacto">
                <a>
                  <button className="text-white tracking-widest uppercase hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-xs dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/contacto", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      CONTACTO
                    </span>
                  </button>
                </a>
              </Link>
            </li>
          </ul>
        </nav>

        {/* SOCIALS  */}              
        <div className="w-full lg:hidden mt-auto">
          <p className="dark:text-jacarta-300 text-jacarta-300 font-thin text-xs">© CIRSYS TECH {new Date().getFullYear()} - All Rights Reserved.</p>
          <hr className="dark:bg-accent bg-accent  my-5 h-px border-0" />
          <div className="flex items-center justify-center space-x-5">
            <a className="group cursor-pointer" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/CIRSYSPeru">
              <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent ease-out duration-200">
                <use xlinkHref={`/icons.svg#icon-facebook`}></use>
              </svg>
            </a>
            <a className="group cursor-pointer" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/irbin.pe/">
              <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent ease-out duration-200">
                <use xlinkHref={`/icons.svg#icon-instagram`}></use>
              </svg>
            </a>
            <a className="group cursor-pointer" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/cirsys/">
              <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent ease-out duration-200">
                <use xlinkHref={`/icons.svg#icon-linkedin`}></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
