const footerMenuList = [
  {
    id: 1,
    title: "CIRSYS",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/",
        text: "Home",
      },
      {
        id: 2,
        href: "/acerca",
        text: "Servicios",
      },
      {
        id: 3,
        href: "/proyectos",
        text: "Proyectos",
      },
      {
        id: 4,
        href: "/contacto",
        text: "Contacto",
      },
      {
        id: 5,
        href: "/privacidad",
        text: "Política de Privacidad",
      },
    ],
  },
  {
    id: 2,
    title: "ACERCA",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/acerca",
        text: "Historia",
      },
      {
        id: 2,
        href: "/acerca",
        text: "Premios",
      },
      {
        id: 3,
        href: "/acerca",
        text: "Equipo",
      },

    ],
  },
  {
    id: 3,
    title: "IRBIN",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/irbin",
        text: "Modelo",
      },
      {
        id: 2,
        href: "/irbin",
        text: "Funcionamiento",
      },
      {
        id: 3,
        href: "/irbin",
        text: "Planes",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/CIRSYSPeru",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.instagram.com/irbin.pe/",
    text: "instagram",
  },
  {
    id: 3,
    href: "https://www.linkedin.com/company/cirsys/",
    text: "linkedin",
  },
];

export { footerMenuList, socialIcons };
